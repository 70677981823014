// Slide animations
@keyframes slide_down {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes slide_right {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}

// Expand animations
@keyframes grow_down {
  0% {
    max-height: 0;
  }
  100% {
    max-height: 100vh;
  }
}

@keyframes grow_up {
  0% {
    max-height: 100vh;
  }
  100% {
    max-height: 0;
  }
}

@keyframes grid_down {
  0% {
    grid-template-rows: 0fr;
  }
  100% {
    grid-template-rows: 1fr;
  }
}

@keyframes grid_up {
  100% {
    grid-template-rows: 0fr;
  }
  0% {
    grid-template-rows: 1fr;
  }
}
