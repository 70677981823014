// BUTTONS
.btn {
  min-height: 40px;
  padding: 12px 25px;
  border: 1px solid transparent;
  font-size: 14px;
  line-height: 1;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;

  i {
    font-size: 1.25rem;

    + span {
      margin-left: 8px;
    }
  }

  &.btn-primary {
    border-radius: 65px;
    font-weight: 700;
    background-color: var(--color-primary-color);
    color: var(--color-primary-white);

    &:hover,
    &:focus {
      background-color: $gray-700;
      border: 1px solid $gray-700;
      color: color-contrast($gray-700);
    }

    &.btn-logo {
      background-color: $gray-300;
      border: 1px solid $gray-200;
      color: $gray-700;

      &:hover,
      &:focus {
        background-color: $white;
        border: 1px solid $gray-300;
      }
    }

    &.btn-action {
      background-color: $blue;
      border: 1px solid $blue;
      color: $white;
      padding: 0.5rem;

      &:hover,
      &:focus {
        background-color: $blue-700;
        border: 1px solid $blue-700;
        color: color-contrast($blue-700);
      }
    }

    &.btn-sm {
      // height: 2rem;
      font-size: 0.75rem;
      padding: 10px 16px;

      i {
        font-size: 0.75rem;

        + span {
          margin-left: 0.375rem;
        }
      }
    }
  }

  &.btn-secondary {
    border-radius: 65px;
    background-color: var(--color-gray-medium);
    border-color: var(--color-primary-color);
    color: var(--color-primary-color);

    &:hover,
    &:focus {
      background-color: $white;
    }
  }

  &.btn-link {
    border: none;
    font-weight: 600;
    width: auto;
    height: 100%;
    border-radius: 0;
    position: relative;
    min-height: auto;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;

    &:after {
      content: '';
      width: 0%;
      height: 1px;
      background-color: var(--color-primary-color);
      position: absolute;
      bottom: 0;
      left: 0;
      -webkit-transition: 0.3s linear;
      transition: 0.3s linear;
    }

    &:hover::after {
      width: 100%;
      height: 2px;
    }

    &.btn-negative {
      color: $gray-100;

      &:hover,
      &:focus {
        color: $gray-400;
      }

      &:disabled {
        background-color: transparent;
        color: $gray-700;
        border: none;
      }
    }
  }

  &.btn-icon {
    border-radius: 0;
    display: flex;
    align-items: center;
    color: var(--color-dark);
    background-color: transparent;
    border: none;
    padding: 15px;
    line-height: 1;

    &.close {
      color: var(--color-primary-white);
    }

    &:disabled,
    &:disabled:hover {
      border: none;
    }
  }

  &:disabled,
  &:disabled:hover {
    background-color: var(--color-gray-dark);
    border: 1px solid var(--color-gray-dark);
    color: rgba(10, 34, 64, 0.6);
  }

  &.btn-sm {
    // height: 2rem;
    font-size: 0.875rem;
    padding: 0.375rem 0.5rem;

    i {
      font-size: 0.75rem;

      + span {
        margin-left: 0.375rem;
      }
    }
  }

  &.btn-danger {
    background-color: $red-600;
    border: 1px solid $red-600;
    color: color-contrast($red-600);

    &:hover,
    &:focus {
      background-color: $red-700;
      border: 1px solid $red-700;
      color: color-contrast($red-700);
    }
  }

  &-dropdown {
    display: flex;
    align-items: center;

    i {
      font-size: 10px;
      margin-left: 5px;
      display: inline-block;
      transition: all 400ms ease-in-out;
    }
  }

  &-underline-hov {
    border: none;
    background-color: transparent;
    position: relative;
    color: var(--color-text);
    font-size: 14px;
    text-transform: uppercase;
    padding: 15px 35px;

    &::after {
      content: ' ';
      border: none;
      position: absolute;
      left: 50%;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);
      bottom: 1px;
      background-color: transparent;
      width: 0;
      height: 2px;
      -webkit-transition: all 0.3s ease-out;
      transition: all 0.3s ease-out;
      margin-left: 0;
    }

    &:hover::after {
      background-color: rgba(0, 87, 183, 0.25);
      width: calc(100% - 30px);
    }

    @media only screen and (max-width: 1400px) {
      padding: 15px 20px;

      &:hover::after {
        width: calc(100% - 40px);
      }
    }

    @media only screen and (max-width: 1199.9px) {
      padding: 15px 6px;

      &:hover::after {
        width: calc(100% - 12px);
      }
    }

    @media only screen and (max-width: 991.9px) {
      padding: 0;

      &::after {
        content: none;
      }
    }
  }
}

button {
  &.disabled {
    pointer-events: none;
  }
  &:focus-visible {
    outline: none;
  }
}

.btn-check:checked + .btn,
:not(.btn-check) + .btn:active,
.btn:first-child:active,
.btn.active,
.btn.show {
  border: none;
}

// LINKS
a {
  text-decoration: none;

  &:focus {
    outline: none;
  }
}

.text-link {
  display: flex;
  align-items: center;
  appearance: none;
  background-color: transparent;
  border: none;

  &:hover {
    span {
      text-decoration: underline;
    }
  }

  i {
    margin-right: 8px;
  }
}

span.circle-arrow-before {
  &::before {
    content: '\f054';
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Font Awesome 6 Pro';
    font-weight: 300;
    color: var(--color-primary-white);
    background-color: var(--color-primary-color);
    width: 16px;
    height: 16px;
    min-width: 16px;
    border-radius: 100%;
    margin-right: 8px;
    line-height: 16px;
    font-size: 8px;
  }
  @media only screen and (min-width: 992px) {
    display: inline-flex;
    align-items: flex-start;
  }

  @media only screen and (max-width: 991.9px) {
    &::before {
      display: none;
    }
  }
}

.qty-select {
  margin: 10px 0;
  position: relative;
  width: 100px;
  display: flex;
  height: 40px;

  input[type='number'] {
    appearance: none;
  }

  .form-control {
    border-radius: 0;
    text-align: center;
    font-size: 14px;
    height: 40px;
    border: none;
    padding: 0;
    border-top: 1px solid var(--color-gray-dark);
    border-bottom: 1px solid var(--color-gray-dark);
  }

  input[disabled] {
    background-color: transparent;
  }

  .spinner-down,
  .spinner-up {
    width: 40px;
    height: 40px;
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    touch-action: manipulation;
    background-color: transparent;
    border: 1px solid var(--color-gray-dark);

    &[disabled],
    &.disabled {
      cursor: not-allowed;

      i {
        color: var(--color-gray-dark);
      }
    }

    i {
      font-size: 12px;
      color: var(--color-dark);
      line-height: 40px;
    }
  }

  .spinner-down {
    border-radius: 40px 0 0 40px;
    border-right: none;
  }

  .spinner-up {
    border-radius: 0 40px 40px 0;
    border-left: 0;
  }
}
